// used to load pages through ajax
// stores loaded pages in sessionStorage
// remembers what pages are present in which elements
// depends on setPage() to call historySetPage() first thing
// uses base(), $bodyClasses, a loader and class .html--loader

// imports --------------------------------------------------------------------
import * as Vars from './0-vars.js';
import * as Listeners from './1-listeners.js';
import * as Once from './2-once.js';
import * as Page from './3-page.js';
import * as Resize from './4-resize.js';
import * as Scroll from "./5-scroll.js";

// exported variables ---------------------------------------------------------
export const historyProjectTitle = $baseTitle;

// local variables ------------------------------------------------------------
// set user variables ----------------------------------------------------------
const historyUseCache = false;

// set default variables -------------------------------------------------------
// don't touch these
let historySwitching = false;
let historySwitchAgain = false;
let historyCanSaveScroll = true;

let historyElement = '';

let historyDoneAnimating = false;
let historyDoneScrolling = false;
let historyDoneLoading = false;

let historyHtml = false;
let historyPageTypeNext = '';
let historyPageTypePrev = '';
let historySelectorPrev = '';
let historyFormData;

let historyCurrent = []; // stores current pages in used selectors
let historyCache = JSON.parse(sessionStorage.getItem('historyCache')); // stores cached pages
if (historyCache === null) historyCache = [];
let historyUrl = window.location.href;
let historyHasLoaded = false;

let historyUsedBrowser = false;
let historyCurrentCacheNr = 0;

let historyLine = document.querySelector('.loader__line');

export const historySetPage = () => {
  // console.log('historySetPage');

  // set body classes, dangerous to use because it removed ALL classes
  // document.body.className = $bodyClasses;
  // base();

  // set pagetypes
  document.body.setAttribute('data-pagetype', $pageType);
  document.body.setAttribute('data-pagetype-next', '');
  historyPageTypeNext = '';

  // add page to cache if not present
  if (!historyIsCached(window.location.href)) {
    const obPage = {
      url: window.location.href,
      scroll: 0,
    };

    // only do this if cache is active to save memory
    if (historyUseCache) obPage.html = document.documentElement.innerHTML;

    historyCache.push(obPage);
    sessionStorage.setItem('historyCache', JSON.stringify(historyCache));
  }

  // add page to current elements
  historySetCurrent();

  // set all popover closing elements to main url
  // CHANGE THIS PER SITE
  // if ($pageType == 'popover') {
  //   for (let j = 0; j < historyCurrent.length; j++) {
  //     if ('.main' == historyCurrent[j].selector) {
  //       document.querySelector('.close').setAttribute('href', historyCurrent[j].url);
  //     }
  //   }
  // }

  // save nr of current page in cache
  for (let i = 0; i < historyCache.length; i++) {
    if (window.location.href == historyCache[i].url) historyCurrentCacheNr = i;
  }
};

const historyIsExternalLink = url => {
  const tmp = document.createElement('a');
  tmp.href = url;
  return tmp.host !== window.location.host;
};

const historyInit = () => {
  // console.log('historyInit');

  // don't remember scroll by browser
  if (history.scrollRestoration) history.scrollRestoration = 'manual';
  // do it using history, so it always works
  historySetScroll();

  // listener for browser buttons
  window.addEventListener('popstate', event => {
    // console.log('popstate');

    if (!historyCheckDefault(window.location.href)) {
      historyInitLoadPage();
      historyUsedBrowser = true;
    }
  }, false);

  // set all forms to method="post"
  document.body.addEventListener('submit', event => {
    const form = event.target.closest('form');
    if (!form) return;

    let action = form.getAttribute('action');
    if (historyIsExternalLink(action)) {
      // external action, don't prevent default, so do nothing
    } else {
      // local action

      // should you do default?
      if (historyCheckDefault(action)) return;

      event.preventDefault();

      // prevent double posting if cf7 and this is the first page (with wp_x functions in base)
      if (form.classList.contains('wpcf7-form') && historyHasLoaded === false) return;

      // close keyboard on iOS
      document.activeElement.blur();

      historyFormData = new FormData(form);

      // for 'get' forms, add form data to URL
      if (form.getAttribute('method') === 'get') {
        const queryString = new URLSearchParams(historyFormData).toString();
        action += '?' + queryString;
        historyFormData = undefined;
      } else if (action.indexOf('wpcf7') > -1) {
        // create history working action for contact form 7
        action = action.substring(0, action.indexOf('wpcf7')) + '?' + Date.now();
      } else {
        // regular post, add timestamp so history works
        action += '?' + Date.now();
      }

      // if in a popover, add 'popover' in query string
      if (form.closest('.popover')) {
        action += action.indexOf('?') > -1 ? '&' : '?';
        action += 'popover';
      }

      // console.log({action});

      // to be able to do stuff with the current link first, wait a frame
      requestAnimationFrame(() => {
        // add new state and load page
        window.history.pushState(null, historyProjectTitle, action);
        historyInitLoadPage();
      });
    }
  });

  // Capture all the links to push their url to the history stack and trigger the StateChange Event
  document.body.addEventListener('click', event => {
    const link = event.target.closest('a');
    if (!link) return;
    // console.log('click', link.getAttribute('href'));

    let href = link.getAttribute('href');
    if (!href) return;

    // check if it is a normal internal link
    if ((link.host === window.location.host
        && href.indexOf('mailto:') === -1
        && href.indexOf('tel:') === -1
        && href.indexOf('/uploads/') === -1
        && href.indexOf('/system-force-download') === -1
        && href.indexOf('/download-pdf') === -1
        && link.getAttribute('target') !== '_blank'
        && !event.ctrlKey
        && !event.metaKey)
      || href.indexOf('?') === 0
    ) {
      // normal local link
      // console.log('normal local');

      // should you do default?
      if (historyCheckDefault(href)) return;

      // don't use the default link behavior on normal local links
      event.preventDefault();

      if (historySwitching) {
        window.history.pushState(null, historyProjectTitle, href);

        historyInitLoadPage();
        return;
      }

      // also done in setpage, but this way it's quicker
      if ('navItemClasses' in Page) {
        for (let i = 0; i < Page.navItemClasses.length; i++) {
          if (link.classList.contains(Page.navItemClasses[i])) {
            document.querySelectorAll('.' + Page.navItemClasses[i]).forEach(element => {
              element.classList.remove(Page.navItemClasses[i] + '--active');
            });

            if (link.classList.contains(Page.navItemClasses[i])) {
              link.classList.add(Page.navItemClasses[i] + '--active');
            }
          }
        }
      }

      // get link without query string and anchor
      const cleanHref = href.split('?')[0].split('#')[0];
      const cleanUrl = window.location.href.split('?')[0].split('#')[0];
      const hrefQuery = href.split('?')[1];
      const urlQuery = window.location.href.split('?')[1];

      // console.log({cleanHref}, {cleanUrl}, {hrefQuery}, {urlQuery});

      // check if clicked href is a new link
      if ((cleanHref === cleanUrl || cleanHref === '') && hrefQuery === urlQuery) {
        // clicked same page
        document.body.classList.remove('body--nav'); // had to be after html--loader to keep menu open ???

        if ($historySelector === '.popover') {
          Scroll.animateScrollTop(0, document.querySelector('.popover'));
        } else {
          // not popover
          if (hasFixer()) Once.fixer.unfixBody();
          
          if (href.indexOf('#') === -1) {
            // not anchor
            Scroll.animateScrollTop(0);
          } else if (!smoothScrollOn()) {
            // anchor and smooth scroll is off
            // manually scroll there, because default was prevented
            const hrefHash = href.split('#')[1];
            const anchorElement = document.getElementById(hrefHash);
            if (anchorElement) scrollToEl(anchorElement);
          }
        }
      } else {
        // clicked link is not current link

        // set next pagetype for animations
        historyPageTypeNext = link.getAttribute('data-pagetype-next');
        if (!historyPageTypeNext) historyPageTypeNext = '';
        document.body.setAttribute('data-pagetype-next', historyPageTypeNext);

        // no WooCommerce and local, so pushState(null, title, url)
        // History.pushState(null, link.text, href);
        // the second parameter is the page title for a short while

        // when in popover open links there
        if (link.closest('.popover')) {
          href += href.indexOf('?') > -1 ? '&' : '?';
          href += 'popover';
        }

        // to be able to do stuff with the current link first, wait a frame
        requestAnimationFrame(() => {
          // add new state and load page
          window.history.pushState(null, historyProjectTitle, href);
          historyInitLoadPage();
        });
      }
    } else {
      // console.log('not normal local');
    }
  });
};

const historyCheckDefault = url => {
  let cleanUrl = url.split('?')[0].split('#')[0];
  // if cleanUrl is empty, set it to the current page's URL, so it doesn't break
  if (cleanUrl === '') cleanUrl = window.location.href;

  // element with url as link
  const a = document.querySelector(`a[href="${url}"]`);

  if (
    historyCheckWoocommerce(cleanUrl) // next is woocommerce
    || historyCheckWoocommerce(window.location.href) // current is woocommerce
    || (typeof hasProducts !== 'undefined' && hasProducts) // current has products
    || cleanUrl.indexOf('add-to-cart') > -1 // next is add to cart
    || document.querySelector(`a[href="${url}"][hreflang]`) // language switch
  ) {
    // console.log('don\'t prevent default');
    return true; // do normal form post or open link
  }

  return false; // do ajax loading
};

export const historyInitLoadPage = () => {
  // console.log('historyInitLoadPage', {historySwitching, historyPageTypeNext});

  historyUsedBrowser = false; // overwritten by listener

  // if you are already switching, just remember for after switch
  // the url is saved in the history stack
  if (historySwitching) {
    historySwitchAgain = true;
    return;
  }

  historyUrl = window.location.href;

  // remember that the page is switching
  historySwitching = true;
  historyCanSaveScroll = false;

  // is the page currently in the dom?
  for (let i = 0; i < historyCurrent.length; i++) {
    if (historyUrl === historyCurrent[i].url) {
      // page is in the dom, do a quick transition
      // console.log('from dom');
      historyBeforeSwitch();
      historyCheckSwitch();
      historyExecuteScript(historyCurrent[i].script);
      historyEndLoading();
      return;
    }
  }

  // do depending on page ---------------------------------
  // just an animation
  // if ($pageType == 'home-from-json') {
  //   document.body.classList.remove('body--content');
  //   document.body.classList.remove('body--txt');
  // }

  // console.log({ historyUrl }, { $homeUrl });

  // do when leaving ANY page -----------------------------

  // document.querySelector('.header').classList.remove('header--white');
  // document.querySelector('.header__bg').className = 'header__bg';

  document.body.classList.remove('body--nav-white');
  document.body.classList.remove('body--nav-black');
  document.body.classList.remove('body--scrolled');

  if (hasFixer()) Once.fixer.unfixBody();

  if (historyLine) historyLine.style.transform = 'scaleX(.33)';

  // document.querySelector('.title').classList.remove('title--hidden');
  if (
    // _url.indexOf('/about/') > -1 || // about page
    // (_url.indexOf('/projects/') > -1 && _url.length > ($url + '/projects/').length) // project
    historyPageTypeNext == 'filter'
  ) {
    // fade only content
    document.documentElement.classList.add('html--loader-filter');

    // 11 is margin
    const filtersTop = itemsTop - filtersHeight - 11;
    if (Scroll.scrollTop < filtersTop) {
      // scroll down to filters
      Scroll.animateScrollTop(filtersTop, document.body, historyStartAnimation);
    } else {
      // from below, instant looks better on mobile
      setTimeout(() => {
        if (smoothScrollOn()) {
          Once.animator.setScrollPosHard({pos: filtersTop});
        } else {
          window.scrollTo({top: filtersTop});
        }

        historyStartAnimation();
      }, Vars.transitionDefault);
    }
  } else {
    // regular load
    document.documentElement.classList.add('html--loader');

    historyStartAnimation();
  }

  // setTimeout(historySetDoneAnimating, Vars.transitionDefault); // for this site
};

const historyStartAnimation = () => {
  // console.log('historyStartAnimation');
  // document.body.classList.remove('body--abc-menu');

  // document.documentElement.classList.add('html--loader');
  // if (historyLine) historyLine.style.transform = 'scaleX(.33)';
  historyLoadPage(historyUrl);

  // if (!(mqPhablet.matches && mqPortrait.matches)) {
  //   document.body.classList.remove('body--nav');
  // }
  // console.log('historyStartAnimation');


  setTimeout(() => {
    if (document.body.classList.contains('body--nav')) {
      document.body.classList.remove('body--nav');
      Scroll.checkBlockScroll();
    }
  }, Vars.transitionDefault);

  setTimeout(() => {  
    // scroll to 0
    if (historyPageTypeNext !== 'filter') {
      if (smoothScrollOn()) {
        Once.animator.setScrollPosHard({pos: 0});
      } else {
        window.scrollTo({top: 0});
      }
    }

    historySetDoneAnimating();
  }, Vars.transitionDefault * 1);
};

const historyLoadPage = url => {
  // console.log('historyLoadPage', {url}, {historySwitchAgain});

  historyDoneAnimating = false;
  historyDoneScrolling = true; // set to false if using scroll animation
  historyDoneLoading = false;
  historyHtml = false;
  historyHasLoaded = true;

  if (typeof historyFormData === 'object') {
    // from form
    // console.log({ historyFormData });
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.onload = () => historySetDoneLoading(xhr, url);
    xhr.send(historyFormData);
  } else if (historyUseCache && historyIsCached(url)) {
    // not from form, is cached and caching is enabled

    for (let i = 0; i < historyCache.length; i++) {
      if (url === historyCache[i].url) {
        // page is cached, load the page from the cache
        // console.log('use cache');
        historyElement = document.createElement('div');
        historyElement.innerHTML = historyCache[i].html;
        historySetDoneLoading({status: 'cache'});
      }
    }
  } else {
    // not from form, not cached
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onload = () => historySetDoneLoading(xhr, url);
    xhr.send();
  }
};

const historySetDoneLoading = (xhr, url) => {
  // console.log('historySetDoneLoading', xhr, url);
  if (historyLine) historyLine.style.transform = 'scaleX(.66)';

  // do after loading the page -----------------------------
  if (xhr.status !== 'cache') {
    // convert response into a dom element first
    historyElement = document.createElement('div');

    if (xhr.status === 200) {
      historyElement.innerHTML = xhr.response;
    } else {
      historyElement.innerHTML = xhr.response; // same
    }
  }

  // check if received page is the loaded url, for when the server redirected
  if (xhr.responseURL && xhr.responseURL !== url) {
    window.history.pushState(null, historyProjectTitle, xhr.responseURL);
  }

  // makes it possible to scroll immediately after page switch
  // clearTimeout(scrollTimeout);

  historyDoneLoading = true;

  // clear post data, so pages are loaded through get
  historyFormData = undefined;

  if (historyDoneScrolling && historyDoneAnimating) historySetHtml();
};

const historySetDoneScrolling = () => {
  // console.log('historySetDoneScrolling');
  if (historyLine) historyLine.style.transform = 'scaleX(.66)';
  historyDoneScrolling = true;

  if (historyDoneLoading && historyDoneAnimating) historySetHtml();
};

const historySetDoneAnimating = () => {
  // console.log('historySetDoneAnimating');
  if (historyLine) historyLine.style.transform = 'scaleX(.66)';
  historyDoneAnimating = true;

  if (historyDoneLoading && historyDoneScrolling) historySetHtml();
};

const historyBeforeSwitch = () => {
  // console.log('historyBeforeSwitch');

  // because it doesn't register a mouseout when the pointer-events change
  if ('aim' in Once) {
    Once.aim.classList.remove('mousy--prev');
    Once.aim.classList.remove('mousy--next');
    Once.aim.classList.remove('animate-it-mouse--hidden');
    Once.aim.classList.remove('animate-it-mouse--inverted');
    Once.aim.classList.remove('animate-it-mouse--drag');
    Once.aim.classList.remove('animate-it-mouse--drag-hor');
    Once.aim.classList.remove('animate-it-mouse--dragging');
    Once.aim.classList.remove('animate-it-mouse--dragging-hor');
  }

  // remove based on page and next page
  // let el;
  // let els;
  // if ($pageType == 'home' && historyPageTypeNext == 'project') {
  //   el = document.querySelector($historySelector + ' .logo');
  //   if (el) el.remove();

  //   el = document.querySelector($historySelector + ' .media-btn');
  //   if (el) el.remove();

  //   els = document.querySelector($historySelector + ' .media:not(.media--active)');
  //   els.forEach(element => {
  //     element.remove();
  //   });
  // } else if ($pageType == 'about' && historyPageTypeNext == 'project') {
  //   el = document.querySelector($historySelector + ' .content');
  //   if (el) el.remove();

  //   els = document.querySelector($historySelector + ' .media:not(.media--active)');
  //   els.forEach(element => {
  //     element.remove();
  //   });
  // } else {
  //   // regular transition
  //   // needs to be cleared because content is appended
  //   document.querySelector($historySelector).innerHTML = '';
  // }

  // if ($historySelector == '.main') document.body.classList.add('body--main' + historyMainCurrent);

  historyPageTypePrev = $pageType;
  historySelectorPrev = $historySelector;
};

const historySetHtml = () => {
  // console.log('historySetHtml', { historySwitchAgain }, { historyHtml }, { historyPageTypeNext });

  // because scroll may fire twice
  if (historyHtml) return;
  historyHtml = true;

  historyBeforeSwitch();

  // if you don't need an animation, you can check the switch here
  if (historyCheckSwitch()) return;

  // fix for not when mouseleave isn't triggered
  if ('animator' in Once) Once.animator.leaveIframe();

  // stops setFaders and setResize from being called before setPage
  if ('fader' in Once) Once.fader.blockResizeObserver = true;
  Resize.setBlockResizeObserver(true);

  // needs to be done like this to convert html entities
  let el = document.createElement('textarea');
  if (el) el.innerHTML = historyElement.querySelector('title').innerHTML;
  document.title = el.value;

  historyExecuteScript(historyElement.querySelector('#vars script').innerHTML);

  // console.log({historyPageTypeNext});
  if (historyPageTypeNext === 'filter') {
    // separate because it is in another possible selector
    $historySelector = '.items';
  }

  el = document.querySelector($historySelector);
  if (el) el.innerHTML = historyElement.querySelector($historySelector).innerHTML;

  // fix putting page in seperate cache
  // before it thought the page was still in the dom
  if ($historySelector === '.items') $historySelector = '.main';

  // let imagesLoaded = 0;
  // const loadImages = document.querySelectorAll($historySelector + ' img[src]:not([src=""]):not([src="data:,"])');
  // const imagesTotal = loadImages.length;

  // set page used in js
  // vars are gotten through the script that is in the body!!!

  // do when adding ANY page ----------------------------------
  // this is EVERYTHING that happens outside of the replaced element,
  // and is normally set in PHP
  // document.querySelector('.submenu').classList.remove('submenu--active');

  // Listeners.gotoSection();

  // do depending on the page --------------------------------
  // this is EVERYTHING that happens outside of the replaced element,
  // and is normally set in PHP

  // if ((historyPageTypePrev == 'home' || historyPageTypePrev == 'about') && $pageType == 'project') {
  //   // remove 1st media, if you still have that one from the previous page
  //   document.querySelectorAll('.media')[1].remove();
  // }

  // // use this for checking if all the images have loaded
  // // console.log(imagesTotal);
  // if (imagesTotal > 0) {
  //   loadImages.forEach(image => {
  //     image.addEventListener('load', () => {
  //       imagesLoaded++;

  //       // console.log('Loaded: ' + imagesLoaded + ' of: ' + imagesTotal + ' images');
  //       const fraction = 0.5 + (imagesLoaded / imagesTotal) * 0.4;
  //       document.querySelector('.loader__line').style.transform = `scaleX(${fraction})`;
  //       document.querySelector('.loader__icon').style.clipPath = `circle(${fraction * 60}px at center)`;

  //       if (imagesLoaded === imagesTotal) {
  //         // console.log('loaded all images');

  //         historyEndLoading();
  //       }
  //     });
  //   });
  // } else {
  //   // no images in the site
  //   historyEndLoading();
  // }
  historyEndLoading();

  // console.log({historyUsedBrowser});

  // console.log('loaded new page');
};

// execute script from loaded page ---------------
const historyExecuteScript = html => {
  // this is the way to execute script in html using js
  let el = document.createElement('script');
  el.innerHTML = html;
  document.getElementById('vars').innerHTML = '';
  document.getElementById('vars').appendChild(el);
};

// does final animations -------------------------
const historyEndLoading = () => {
  // console.log('historyEndLoading');
  historyElement = undefined;

  let el;

  // reset loader bar
  if (historyLine) historyLine.style.transform = 'scaleX(1)';

  if (historyPageTypeNext === 'filter') {
    document.documentElement.classList.remove('html--loader-filter');
    Page.setPage();
  } else {
    document.documentElement.classList.remove('html--loader');
    Page.setPage();

    setTimeout(() => {
      // set scroll after a timeout, so maxBodyScroll is correct, but before main fadein
      if ($historySelector === '.main' && historySelectorPrev === '.main') {
        if (hasFixer()) Once.fixer.unfixBody();

        if (historyUsedBrowser) {
          // if used browser buttons, go to previous scroll of that page
          historySetScroll(); // only works on main now

          // not via buttons, so go to top
        } else if (smoothScrollOn()) {
          Once.animator.setScrollPosHard({pos: 0});
        } else {
          window.scrollTo({top: 0});
        }
      } else {
        // not to main
        el = document.querySelector($historySelector);
        if (el) el.scrollTo(0, 0);
      }

      // after Page.setPage, so historySetPage was called and it saves to the correct page
      historyCanSaveScroll = true;
      historySaveScroll();
    }, 10);
  }

  setTimeout(() => {
    dispatchEvent(new Event('load'));
  }, Vars.transitionDefault);

  setTimeout(() => {
    if (historyLine) historyLine.style.transform = 'scaleX(0)';

    // if you needed an animation, check the switch now!
    // if (historyCheckSwitch()) return;
  }, Vars.transitionDefault + (Vars.transitionSlow * 2)); // after transition
};

// functions -------------------------------------------------------------------
// check if you need to switch again ----------------------------
const historyCheckSwitch = () => {
  // console.log('historyCheckSwitch', {historySwitchAgain});

  // did you try and go to another page while historySwitching?
  historySwitching = false;

  if (historySwitchAgain) {
    historySwitchAgain = false;

    historyInitLoadPage();

    // stop what you're doing
    return true;
  }

  return false;
};

// check if the url is a woocommerce page -----------------------
const historyCheckWoocommerce = _url => {
  // remove querystring
  _url = _url.split('?')[0];

  if (typeof $wcPages != 'undefined') {
    // woocommerce active

    // loop through woocommerce pages
    // console.log(_url);
    for (let i = 0; i < $wcPages.length; i++) {
      // console.log($wcPages[i]);
      // console.log(_url.indexOf($wcPages[i]));
      if (_url.indexOf($wcPages[i]) > -1) {
        // url is a woocommerce page
        return true;
      }
    }
  }

  if (_url.includes('/product/')) return true; // url is also a woocommerce page

  // not a woocommerce page
  return false;
};

// adds pages currently in the dom to historyCurrent ----------------
// saves in which element they are too
const historySetCurrent = () => {
  // console.log('historySetCurrent', historyUrl);
  // create current info
  const obCurrent = {
    url: historyUrl,
    script: document.querySelector('#vars script').innerHTML,
    selector: $historySelector,
  };

  // if page with current selector already saved
  let isCurrent = false;
  for (let i = 0; i < historyCurrent.length; i++) {
    if ($historySelector == historyCurrent[i].selector) {
      // console.log('update current', { obCurrent });

      // update current selector
      historyCurrent[i] = obCurrent;
      isCurrent = true;
    }
  }

  // page with current selector didn't exist yet
  if (!isCurrent) historyCurrent.push(obCurrent);
};

// checks if the given url is cached in var/sessionStorage ----
const historyIsCached = _url => {
  let inCache = false;
  for (let i = 0; i < historyCache.length; i++) {
    if (_url == historyCache[i].url) {
      inCache = true;
    }
  }
  // console.log('is cached? ', inCache);

  return inCache;
};

const historySaveScroll = () => {
  // save scroll for current page
  // console.log('historySaveScroll', {historySwitching, historyCurrentCacheNr});

  if (!historySwitching && historyCanSaveScroll) {
    // console.log({Scroll.scrollTop}, historyCache[historyCurrentCacheNr].url);
    if (typeof fixBody != 'undefined' && typeof fixBody.bodyFixed != 'undefined') {
      if (fixBody.bodyFixed != true) {
        historyCache[historyCurrentCacheNr].scroll = Scroll.scrollTop;
      }
    } else {
      historyCache[historyCurrentCacheNr].scroll = Scroll.scrollTop;
    }
  }
};

const historySetScroll = () => {
  // console.log('historySetScroll');

  // get nr of loaded page in cache
  let loadedCacheNr;
  for (let i = 0; i < historyCache.length; i++) {
    if (window.location.href == historyCache[i].url) loadedCacheNr = i;
  }

  if (typeof loadedCacheNr === 'undefined') return;

  const savedScroll = historyCache[loadedCacheNr].scroll;

  if (smoothScrollOn()) {
    Once.animator.setScrollPosHard({pos: savedScroll});
  } else {
    window.scrollTo({top: savedScroll});
  }
};

const smoothScrollOn = () => {
  return 'animator' in Once && Once.animator.scroller !== undefined;
}

const hasFixer = () => {
  return 'fixer' in Once && typeof Once.fixer.bodyFixed !== 'undefined';
}

// jquery loaded ----------------------------------------------------------------
document.addEventListener('DOMContentLoaded', historyInit);
document.addEventListener('scroll', historySaveScroll);
window.addEventListener('beforeunload', event => {
  // used to save scroll position
  sessionStorage.setItem('historyCache', JSON.stringify(historyCache));
});
