// do these things only once
// even if you are using a history.js navigation

// imports --------------------------------------------------------------------
import Fader from '../js_vendor/Fader.js';
import FoldOut from '../js_vendor/FoldOut.js';
import Switcher from '../js_vendor/Switcher.js';
import * as Vars from './0-vars.js';
import * as Resize from './4-resize.js';

// exported variables ---------------------------------------------------------
export let fader;
// export let smoothScrollInactive;
// export let animator;
// export let aim = 'hoi'; // for testing
// export let fixer;
export let switcher;

// export let sunWiggler;

const navBgEl = document.querySelector('.nav__bg');

// console.log('mqMobile' in Vars);

// local variables ------------------------------------------------------------
let calculateFoldOut;
let calculateDoelFoldOut;
let resultsAfspraakFoldOut;

// once function --------------------------------------------------------------
const once = () => {
  // console.log('once');
  
  // delay so fader classes are already given in page js on page load
  // can't be requestAnimationFrame because if you open in a new tab, there is no new frame
  setTimeout(() => {
    // add Fader
    fader = new Fader({
      fadeOnInit: false,
      mobileWidth: 800,
      stagger: 50,
      fadeDur: 500,
      maxFades: 10,
      // afterLoadFunction: Resize.saveOffsets,
      skipOnLoadInView: true,
    });

    switcher = new Switcher({
      // duration: 400, // set to --switcher-duration
      afterFadeOutCallback: fader.setFaders,
      afterResizeCallback: Resize.setResize,
    });
  }, 20); // less than page js fader delay

  // see example.js for blocking resize observer during animation
  // add calculateFoldOut
  calculateFoldOut = new FoldOut({
    buttonClass: 'calculate__button',
    holderClass: 'calculate',
    callback: Resize.setResize,
    closeOthers: false,
    // closeAllWithSameHolderClass: true,
    // transitionDuration: 600, // also change _fold-out.scss
    // callbackDuringAnimation: false,
  });

  // add calculateDoelFoldOut 
  calculateDoelFoldOut = new FoldOut({
    buttonClass: 'js-calculate-doel',
    holderClass: 'calculate',
    callback: Resize.setResize,
    closeOthers: true,
  });

  // add resultsAfspraakFoldOut
  resultsAfspraakFoldOut = new FoldOut({
    buttonClass: 'results__button--other',
    holderClass: 'js-results-form-afspraak',
    callback: Resize.setResize,
    closeOthers: true,
  });


  // start frame animation using requestAnimationFrame without animator
  const frameHandler = () => {
    // console.log('frameHandler');
    // do something

    if (navBgEl) {
      const background = getComputedStyle(navBgEl).getPropertyValue('background-color');
      document.querySelector('meta[name="theme-color"]').setAttribute('content', background);
    }

    window.requestAnimationFrame(frameHandler);
  };

  window.requestAnimationFrame(frameHandler);

  // output name for devvers ----------------------------------
  console.log('%cSite by', 'color: #999; font-size: 16px; font-family: "Arial"; font-weight: bold; text-transform: uppercase;');
  console.log('%cLittle Fragments', 'color: #999; font-size: 24px; font-family: "Arial"; font-weight: bold; text-transform: uppercase;');
};

// document ready listener
document.addEventListener('DOMContentLoaded', once);
